<template>
  <div>
    <v-card>
      <h2 class="pl-2 mb-10">
        Adres URL śledzenia
      </h2>
      <v-row class="d-flex align-center px-6 pb-5">
        <v-col cols="6">
          <v-text-field
            label="Adres URL śledzenia"
            v-model="trackingUrl"
            autocomplete="none"
            :disabled="!isEditing">
          </v-text-field>
        </v-col>
        <v-col cols="6" class="d-flex">
          <div v-if="isEditing">
            <v-btn color="blue" class=" white--text" @click.stop="updateUrl()">
              Zapisz zmiany
              <v-icon class="ml-2 white--text">mdi-archive-arrow-up</v-icon>
            </v-btn>
            <v-btn color="blue" class="ml-3 white--text error" @click.stop="cancelEdit()">
              Odrzuć zmiany
              <v-icon class="ml-2 white--text">mdi-cancel</v-icon>
            </v-btn>
          </div>
          <div v-else>
            <v-btn color="blue" class=" white--text warning" @click.stop="() => isEditing = !isEditing">
              Edytuj
              <v-icon class="ml-2 white--text">mdi-file-edit-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center mt-n14 ml-1 px-6 pb-5">
        <v-col style="height: 30px" cols="6">
          <span v-if="displayError" style="color: #DC3545">Wystąpił błąd!</span>
        </v-col>
      </v-row>
      <div style="clear:both"></div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'TrackingUrl',
  data () {
    return {
      trackingUrl: '',
      isEditing: false,
      getTrackingUrlEndpoint: '/trackingUrl/GetTrackingUrl',
      updateTrackingUrlEndpoint: '/trackingUrl/UpdateTrackingUrl',
      displayError: false
    }
  },
  methods: {
    async getTrackingUrl () {
      const result = await this.$http.get(this.getTrackingUrlEndpoint)
      this.trackingUrl = result.data.url
    },
    async updateUrl () {
      try {
        const response = await this.$http.put(this.updateTrackingUrlEndpoint, { url: this.trackingUrl })
        if (response.status === 200) {
          this.isEditing = false
        } else {
          this.displayError = true
          setTimeout(() => {
            this.displayError = false
          }, 3000)
        }
      } catch (error) {
        this.displayError = true
        setTimeout(() => {
          this.displayError = false
        }, 3000)
      }
    },
    async cancelEdit () {
      const result = await this.$http.get(this.getTrackingUrlEndpoint)
      this.trackingUrl = result.data.url
      this.isEditing = false
      this.displayError = false
    }
  },
  mounted () {
    this.getTrackingUrl()
  }
}
</script>
